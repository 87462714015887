import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Transition, Label, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import { TagColors } from './HomeHelper.js';
import {nounoptions1, nounoptions2, options1, options2, options3, customFontFam3, customTitleFontFam3, customFirefoxTitleFontFam} from './constants/constants.js'
import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;
// let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

let customFontFam = customFontFam3
let customTitleFontFam = customTitleFontFam3
let customFontFam2 = {fontFamily:customTitleFontFam3}
let letterbutton = {fontFamily:customFontFam3,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
const isFirefox = typeof InstallTrigger !== 'undefined';

if (isFirefox) {
  customFontFam = customFirefoxTitleFontFam
  customTitleFontFam = customFirefoxTitleFontFam
  customFontFam2 = {fontFamily:customFirefoxTitleFontFam}
  letterbutton = {fontFamily:customFirefoxTitleFontFam,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
}

let nonLinguistic = {
	'imperative':'command',
	'imperative_singular':'command',
	'imperative_plural':'command plural',
	'prohibitive':'do not',
	'prohibitive_singular':'do not',
	'prohibitive_plural':'do not plural',
	'perfective':'completed',
	'perfective_habitual':'every time',
	'progressive_imperfective':'present, in-progress',
	'repetitive_imperfective':'regularly',
	'imperfective':'present, not completed',
	'imperfective_habitual':'always',
	'perfective_habitual_negative':'has not yet',
	'potential_attributive':'no way can',
	'conditional':'if/when',
	'hortative':'let',
	'imperfective_decessive':'used to',
	'imperfective_decessive_negative':"didn't used to",
	'potential_decessive':'would have',
	// 'imperfective_prohibitive':'do not',
	// 'perfective_prohibitive':'do not',
	// 'perfective_negative':'completed negative'


}

class Entry extends Component {
	constructor(props) {
		super(props);

    // console.log(decodeURI(props.match.params.word))

		// console.log(props)
		this.state = {
			word: decodeURI(props.match.params.word),
			history: props.location.state === undefined ? [] : props.location.state.history,
			from: props.location.state === undefined ? '' : props.location.state.from,
			prevCategory: props.location.state === undefined ? 'all' : ('currentCategory' in props.location.state ? props.location.state.currentCategory:'all'),
			parseIndex:0,

			children:[],
    	lexicon:'',
    	pos:'',
    	varsp:[],
    	gloss:[],
    	soundfile:'',
    	semantic_domain:[],
    	dialect:[],
    	reversal:[],
    	examples:[],
    	Possessed:'',
    	Verb_Root:'',
    	LeerEdwards_Theme:[],
    	NaishStory_Theme:'',
    	// Conjugation_Prefix:'',
    	// Motion_Verbtype:'',
    	// Valence:'',
    	// Pronoun:'',
    	notes1:[],
    	notes2:[],
    	verb_paradigms:[],
    	verb_paradigms_roots:[],
    	noun_paradigms:[],
    	adverb_paradigms:[],
			siblings:[],
			homonyms:[],

    	loaded:false,
		}
	    // this.getWord = this.getWord.bind(this);
	    // this.getWord(decodeURI(props.match.params.word));
	    scroll.scrollTo(0);
	}

	componentDidMount(){
		// this.getWord(decodeURI(this.props.match.params.word));
    if (decodeURI(this.props.match.params.word) == 'phrasebook') {
      this.props.history.push('/phrasebook/1')            
    } else if (decodeURI(this.props.match.params.word) == 'dialogues') {
      this.props.history.push('/dialogues/1')            
    } else {
    	this.initializeWord()
    }		


	}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.word !== this.props.match.params.word) {
    	this.initializeWord()
    	scroll.scrollTo(0);
    }
  }


  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({ 
			from: nextProps.location.pathname,
			// fullDefinition: nextProps.fullDefinition,
		})
	}

	initializeWord() {

	  axios
      .get(API_URL + "/tlingitword/" + decodeURI(this.props.match.params.word))
      .catch(error => {
      	// console.log(error,this.props)
		    return this.props.history.push('/')
		  })
      .then(response => {
        // let end = now();
        // ReactGA.timing({
        //   category: 'Loading',
        //   variable: 'dictionary',
        //   value: (end-start).toFixed(3),
        //   label: 'Dictionary loading'
        // });
        // audiolibrary = response.data;
        // fuse.setCollection(dictionary);
        // fuse1.setCollection(dictionary);
        // console.log(response.data);
        let entryResults = response.data


		    this.setState({
		    	lexicon:entryResults.lexicon === undefined ? '' : entryResults.lexicon,
		    	pos:entryResults.pos === undefined ? '' : entryResults.pos,
		    	varsp:entryResults.varsp === undefined ? [] : entryResults.varsp,
		    	children:entryResults.children === undefined ? [] : entryResults.children,
		    	dialect:entryResults.dialect === undefined ? [] : entryResults.dialect,
		    	gloss:entryResults.gloss === undefined ? [] : entryResults.gloss,
		    	soundfile:entryResults.soundfile === undefined ? '' : entryResults.soundfile,
		    	semantic_domain:entryResults.semantic_domain === undefined ? [] : entryResults.semantic_domain,
		    	reversal:entryResults.reversal === undefined ? [] : entryResults.reversal,
		    	notes2:entryResults.notes2 === undefined ? [] : entryResults.notes2,
		    	examples:entryResults.examples === undefined ? [] : entryResults.examples,
		    	Possessed:entryResults.Possessed === undefined ? '' : entryResults.Possessed,
		    	examples_parse:entryResults.examples_parse === undefined ? {} : entryResults.examples_parse,

		    	Verb_Root:entryResults.Verb_Root === undefined ? '' : entryResults.Verb_Root,
		    	LeerEdwards_Theme:entryResults.LeerEdwards_Theme === undefined ? [] : entryResults.LeerEdwards_Theme,
		    	NaishStory_Theme:entryResults.NaishStory_Theme === undefined ? '' : entryResults.NaishStory_Theme,
		    	reversal:entryResults.reversal === undefined ? [] : entryResults.reversal,
		    	// Conjugation_Prefix:entryResults.Conjugation_Prefix === undefined ? '' : entryResults.Conjugation_Prefix,
		    	// Motion_Verbtype:entryResults.Motion_Verbtype === undefined ? '' : entryResults.Motion_Verbtype,
		    	// Valence:entryResults.Valence === undefined ? '' : entryResults.Valence,
		    	// Pronoun:entryResults.Pronoun === undefined ? '' : entryResults.Pronoun,
		    	semantic_domain:entryResults.semantic_domain === undefined ? [] : entryResults.semantic_domain,
		    	notes1:entryResults.notes1 === undefined ? [] : entryResults.notes1,
		    	notes2:entryResults.notes2 === undefined ? [] : entryResults.notes2,
		    	verb_paradigms:entryResults.verb_paradigms === undefined ? [] : entryResults.verb_paradigms,
		    	verb_paradigms_roots:entryResults.verb_paradigms_roots === undefined ? [] : entryResults.verb_paradigms_roots,
		    	noun_paradigms:entryResults.noun_paradigms === undefined ? [] : entryResults.noun_paradigms,
		    	adverb_paradigms:entryResults.adverb_paradigms === undefined ? [] : entryResults.adverb_paradigms,
		    	siblings:entryResults.siblings === undefined ? [] : entryResults.siblings,
		    	homonyms:entryResults.homonyms === undefined ? [] : entryResults.homonyms,

		    	loaded:true,
				})

        // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
        // dictionary_prepared = fuzzysort.prepare(dictionary)

        // this.setState({ audiolibrary: audiolibrary });
      });
	}

  // getWord(word) {
  // 	scroll.scrollTo(0);
  // 	if (word in this.props.urlDictionary) {
  // 		console.log(this.props.urlDictionary[word])
	 //    this.setState({
	 //    	lexicon:this.props.urlDictionary[word].lexicon === undefined ? '' : this.props.urlDictionary[word].lexicon,
	 //    	pos:this.props.urlDictionary[word].pos === undefined ? '' : this.props.urlDictionary[word].pos,
	 //    	varsp:this.props.urlDictionary[word].varsp === undefined ? [] : this.props.urlDictionary[word].varsp,
	 //    	children:this.props.urlDictionary[word].children === undefined ? [] : this.props.urlDictionary[word].children,
	 //    	dialect:this.props.urlDictionary[word].dialect === undefined ? [] : this.props.urlDictionary[word].dialect,
	 //    	gloss:this.props.urlDictionary[word].gloss === undefined ? [] : this.props.urlDictionary[word].gloss,
	 //    	soundfile:this.props.urlDictionary[word].soundfile === undefined ? '' : this.props.urlDictionary[word].soundfile,
	 //    	semantic_domain:this.props.urlDictionary[word].semantic_domain === undefined ? [] : this.props.urlDictionary[word].semantic_domain,
	 //    	reversal:this.props.urlDictionary[word].reversal === undefined ? [] : this.props.urlDictionary[word].reversal,
	 //    	notes2:this.props.urlDictionary[word].notes2 === undefined ? [] : this.props.urlDictionary[word].notes2,
	 //    	examples:this.props.urlDictionary[word].examples === undefined ? [] : this.props.urlDictionary[word].examples,
	 //    	Possessed:this.props.urlDictionary[word].Possessed === undefined ? '' : this.props.urlDictionary[word].Possessed,

	 //    	Verb_Root:this.props.urlDictionary[word].Verb_Root === undefined ? '' : this.props.urlDictionary[word].Verb_Root,
	 //    	LeerEdwards_Theme:this.props.urlDictionary[word].LeerEdwards_Theme === undefined ? [] : this.props.urlDictionary[word].LeerEdwards_Theme,
	 //    	NaishStory_Theme:this.props.urlDictionary[word].NaishStory_Theme === undefined ? '' : this.props.urlDictionary[word].NaishStory_Theme,
	 //    	reversal:this.props.urlDictionary[word].reversal === undefined ? [] : this.props.urlDictionary[word].reversal,
	 //    	Conjugation_Prefix:this.props.urlDictionary[word].Conjugation_Prefix === undefined ? '' : this.props.urlDictionary[word].Conjugation_Prefix,
	 //    	Motion_Verbtype:this.props.urlDictionary[word].Motion_Verbtype === undefined ? '' : this.props.urlDictionary[word].Motion_Verbtype,
	 //    	Valence:this.props.urlDictionary[word].Valence === undefined ? '' : this.props.urlDictionary[word].Valence,
	 //    	Pronoun:this.props.urlDictionary[word].Pronoun === undefined ? '' : this.props.urlDictionary[word].Pronoun,
	 //    	semantic_domain:this.props.urlDictionary[word].semantic_domain === undefined ? [] : this.props.urlDictionary[word].semantic_domain,
	 //    	notes1:this.props.urlDictionary[word].notes1 === undefined ? [] : this.props.urlDictionary[word].notes1,
	 //    	notes2:this.props.urlDictionary[word].notes2 === undefined ? [] : this.props.urlDictionary[word].notes2,
	 //    	verb_paradigms:this.props.urlDictionary[word].verb_paradigms === undefined ? [] : this.props.urlDictionary[word].verb_paradigms,
	 //    	verb_paradigms_roots:this.props.urlDictionary[word].verb_paradigms_roots === undefined ? [] : this.props.urlDictionary[word].verb_paradigms_roots,

		// 	})
  // 	} else {
		// 	this.props.history.push('/')
		// }

  // }


	processStyledText = (sentence) => {			
		sentence = sentence.replace("⟨","").replace("⟩","").replace("«","").replace("»","")
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/{.*?}/g)
		let matches2 = sentence.match(/_.*?_/g)
		if (matches !== null || matches1 !== null || matches2 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,m.slice(1,-1)))						
			}
			if (matches2 !== null) {
				matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence}</span>
		}
	}

    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }


	processUnderlyingStyledText = (sentence) => {			
		sentence = sentence.replace("⟨","").replace("⟩","")
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/\^.*?\^/g)
		let matches2 = sentence.match(/_.*?_/g)
		if (matches !== null || matches1 !== null || matches2 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>['+m.slice(1,-1)+']</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
			}
			if (matches2 !== null) {
				matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence}</span>
		}
	}

	processPostbaseExampleRow = (sentence) => {     
    let matches = sentence.match(/\`.*?(\`|\')/g)
    let matches2 = sentence.match(/\(.*?\)/g)

    // console.log(matches2)
    if (matches !== null) {
      matches.map((m) => sentence = '<b>'+sentence.replace(m,'<span style="font-weight:normal">'+m.slice(1,-1)+'</span>')+'</b>')
    }      
    if (matches2 !== null) {
      matches2.map((m) => sentence = sentence.replace(m,'<span style="font-weight:normal"><i>'+m+'</i></span>'))      
    }
    if (matches !== null || matches2 !== null) {
      return <span dangerouslySetInnerHTML={{__html: sentence}} />          
    } else {
      return <span>{sentence}</span>
    }
  }

  processPostbaseTableRowHTML = (sentence) => {
		let matchesB = sentence.match(/\{.*?\}(?!\w)/g)
    let matchesI = sentence.match(/⎡.*?⎤/g)
		let matchesSup = sentence.match(/\^.*?\^/g)
		let matchesSub = sentence.match(/_.*?_/g)
		if (matchesB !== null || matchesI !== null || matchesSup !== null || matchesSub !== null) {
			if (matchesB !== null) {
				matchesB.map((m) => sentence = sentence.replaceAll(m,'<b>'+m.slice(1,-1)+'</b>'))								
			}
			if (matchesI !== null) {
				matchesI.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))						
			}
			if (matchesSub !== null) {
				matchesSub.map((m) => sentence = sentence.replaceAll(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			if (matchesSup !== null) {
				matchesSup.map((m) => sentence = sentence.replaceAll(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
			}
			return sentence
		} else {
			return sentence
		}
  }

  returnNonLinguisticTerm = (term) => {
  	if (term in nonLinguistic) {
  		return <div style={{fontSize:'16px',color:'#777777',fontStyle:'italic',paddingTop:'10px',paddingRight:'15px'}}>
                <Popup hideOnScroll on='click' position='bottom right' content={
                  <div style={{fontFamily:customFontFam,}}>
                    {term.replaceAll('_',' ')}
                  </div>
                } trigger={
                  <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                  	{nonLinguistic[term]}
                  </span>
                } />
						</div>  		
  	} else {
  	return 	<div style={{fontSize:'16px',color:'#777777',fontStyle:'italic',paddingTop:'10px',paddingRight:'15px'}}>
							{this.processStyledText(term.replaceAll('_',' '))}
						</div>  		
					}
  }

  // processPostbaseTableRow = (sentence) => {     
		// let matchesBnL = sentence.match(/\{(.*?)\}⟨(.*?)⟩/g)
		// let splitSentence = []
		// let sentenceBank = []
		// let restOfSentence = sentence
		// if (matchesBnL !== null) {
		// 		let before = ''
		// 		let after = ''
		// 		matchesBnL.map((m) => {
		// 			before = restOfSentence.slice(0, restOfSentence.indexOf(m));
		// 			after = restOfSentence.slice(restOfSentence.indexOf(m)+m.length + 1);
		// 			sentenceBank.push(before)
		// 			restOfSentence = after
		// 		})
		// 		sentenceBank.push(after)
		// 		splitSentence = sentence.split(matchesBnL[0])
		// 		return <span>
		//  		<span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(sentenceBank[0])}} />

		// 	 		{matchesBnL.map((k,index)=><span>			 			
		// 			<Link style={{color:'#306190'}} to={{pathname: k.match(/⟨.*?⟩/g)[0].slice(1,-1)}} onClick={()=>{this.setState({key:k.match(/⟨.*?⟩/g)[0].slice(1,-1), from:this.props.location.pathname})}}><b>{k.match(/\{.*?\}/g)[0].slice(1,-1)}</b></Link>
		// 			{' '}
		// 	 		<span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(sentenceBank[index+1])}} />			
		// 	 		</span>	 			
		//  		)}
		// 		</span>
		// } else {
		//  	return <span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(this.processPostbaseTableRowHTML(sentence))}} />		
		// }
  // }

  repeatAudio(audio, event, data) {
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/tlingitaudiolibrary/" +  audio);
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }



	render() {
		// console.log(this.state)
		let postbaseTableOn = false
    let postbaseExampleOn = false

    let testList = [[
	"Ax̲", [
		{"word": "ax̲", "span": [0, 3], "links": 19}]],[
	"atx'aan hídi", [
		{"word": "atx'aan hídi", "span": [4, 16], "links": 1}, 
		{"word": "atx'aan", "span": [4, 11], "links": 1}, 
		{"word": "hídi", "span": [12, 16], "links": 3}]],[
	"tleidooshú", [
		{"word": "tleidooshú", "span": [17, 27], "links": 1}]],[
	"k̲aa x̲'oos", [
		{"word": "k̲aa x̲'oos", "span": [28, 39], "links": 2}, 
		{"word": "k̲aa", "span": [28, 32], "links": 37}, 
		{"word": "x̲'oos", "span": [33, 39], "links": 5}]],[
	"k̲a", [
		{"word": "k̲a", "span": [40, 43], "links": 6}]],[
	"daax'oonx̲", []],[
	"sitee.", [
		{"word": "sitee", "span": [55, 61], "links": 1}]]]
		// console.log(this.props.location)
		// console.log(this.props)
		return (
			<div className="app" style={{fontFamily:customFontFam}}>
			<Transition visible={this.state.loaded} animation='fade' duration={300}>
      <Grid textAlign='center'>
      <Grid.Row  style={{height:40,paddingBottom:0}}>
      <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>
					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { currentCategory:this.state.prevCategory, history:this.state.history }}} >
	          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	          </Link>
						:
          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
					}
					<Link to={{pathname: "/"}}>
          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
					</Link>					

					<div style={{fontSize:'23px',marginTop:'20px',fontFamily:customFontFam}}>
						<div>
          		<div style={{display:'flex',paddingBottom:'7px'}}> 
              <span style={{fontWeight:'500',lineHeight:'30px'}}>
              {this.processStyledTextLink(this.state.lexicon)}
              	{typeof this.state.soundfile !== "undefined" && this.state.soundfile.length !== 0 ?
									<Icon circular onClick={()=>this.repeatAudio(this.state.soundfile)} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
									:
									null
              	}
              </span>


                <span style={{'marginLeft':'12px',marginTop:'3px'}}>
	              {this.state.dialect.length !== 0 ?
	              	(this.state.dialect.map((d)=>
		                <span style={{marginRight:'5px'}}>
		                  <TagColors tags={d} width={-1} />
		                </span>
		                )
	              	)
	                :
	                ''
	              }   
	              {this.state.pos !== '' ?
	                <span style={{marginRight:'5px'}}>
	                  <TagColors tags={this.state.pos} width={-1} />
	                </span>
	                :
	                ''
	              }       
	              </span>      
              </div>
            </div>
					</div>

					{typeof this.state.varsp !== undefined && this.state.varsp.length !== 0 ?
						<div style={{fontStyle:'italic',fontSize:'18px',marginTop:'5px',color:'#000000cc'}}>
							{this.state.varsp.map((v)=>
								<div style={{marginBottom:'8px',lineHeight:'22px'}}>
									{v[0].replace("⎡","[").replace("⎤","]").replace('pl.','plural').replace("⎡or⎤","or")+'\n\n'}
								
	                <span style={{'marginLeft':'12px',marginTop:'3px'}}>
		              {v[1].length !== 0 ?
		              	(v[1].map((d)=>
			                <span style={{marginRight:'5px'}}>
			                  <TagColors tags={d} width={-1} />
			                </span>
			                )
		              	)
		                :
		                ''
		              }      
		              </span>

								</div>
							)}
						</div>
						:
						null
					}



					<div style={{border:'1px solid #E3E3E3',marginTop:'14px'}}>


					{this.state.gloss.length != 0 ?
					<div>
						<div className='hierarchymain'>
						<span className='span1'></span>
						<span className='span2'>Definition</span>
						</div>

						<div style={{marginTop:'17px',marginBottom:'18px'}}>
						{this.state.gloss.map((d,ind) => 
							<div>
							<div style={{display:'flex',alignItems:'center',margin:'13px 0px'}}>
							<span style={{marginLeft:'10px',color:'#777777',fontSize:'20px','fontWeight':'300'}}>{'○'}</span>
							<span style={{marginLeft:'20px',color:'#000000',fontSize:'20px',lineHeight:'24px'}}>
							{this.processStyledText(d)}
							</span>
							</div>

							{this.state.Possessed.length > 0 ?
								<div style={{marginLeft:'45px',fontSize:'16px',color:'grey',marginBottom:'18px'}}> {this.state.Possessed+': his/her/its '+d} </div>
								:
								null
							}
							</div>

							)}
						</div>
						</div>
						:
						null
					}

					{this.state.children.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Children</span>
							</div>
							<List style={{marginTop:0}} divided>
							{this.state.children.map((key, index) =>
								{
								let word = key
						    return <List.Item style={{padding:7,paddingTop:11,paddingBottom:11}} key={word.url}>
						    <Link to={{pathname: '/' + word.url}} onClick={()=>{this.setState({key:word.url, from: this.props.location.pathname})}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'2px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
						    </Link>
						      <List.Content  style={{paddingRight:'16px'}}>
						        <List.Header style={{fontSize:'16px',paddingBottom:'2px',fontFamily:customFontFam,lineHeight:'25px',paddingLeft:'13px'}}>
				          		<div style={{display:'flex',alignItems:'center'}}> 
                        <Link style={{color:'#306190'}} to={{pathname: '/' + word.url, state: { word: word.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  				              <span style={{'paddingRight':'3px',fontWeight:'500'}}> {/* ,borderBottom:'1px solid #858585',paddingBottom:'1px', */}
                          {this.processStyledTextLink(word.lexicon)}
                        </span>
                        </Link>

                        {'soundfile' in word ?
                          <Icon circular onClick={()=>this.repeatAudio(word.soundfile)} style={{marginLeft:'10px',fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
                          :
                          null
                        }

                        {'dialect' in word ?
                          (word.dialect.map((d,dindex)=>
                            <span style={{'marginLeft':(dindex == 0 ? '12px':'0px'),marginRight:'6px'}}>  
                              <span style={{display:'flex',marginRight:'5px'}}>
                                <TagColors homeMode={0} tags={d} width={window.innerWidth} />
                              </span>
                            </span>
                          ))
                          :
                          null
                        }
                        <span style={{'marginLeft':('dialect' in word ? '0px':'12px'),marginRight:'6px'}}>  
                          <span style={{display:'flex',marginRight:'5px'}}>
                            <TagColors homeMode={0} tags={word.pos} width={window.innerWidth} />
                          </span>
                        </span>

				              </div>
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(word.gloss.join('; '))}</List.Description>
						      </List.Content>
						    </List.Item>
              	})}
							</List>

						</div>
						:
						null
					}



					{this.state.examples.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Example Sentences</span>
							</div>

							{this.state.examples.map((d,ind) => 
								<div style={{marginTop:'18px'}}>



									<div style={{marginTop:'15px',marginLeft:'22px',marginBottom:'5px',fontSize:'20px',lineHeight:'34px'}}>

	                {d[1].map((t,tindex)=>{
	                	let entries = [...t[1],...t[2]]
	                	let maxLength = (entries.length % 2 == 0 ? entries.length/2 : (entries.length+1)/2)
	                	if (entries.length === 0) {

	                  	return <span style={{ marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'6px',fontSize:'20px', borderBottom:'2px dotted white'}}>
	                      {this.processStyledText(t[0])}
	                    </span>

	                	} else {

		                  return <Popup on='click' position='bottom left' onClose={()=>{this.setState({parseIndex:0})}} content={
		                    <div style={{fontFamily:customFontFam, fontSize:'16px'}}>
		                    	<div style={{textAlign:'center',fontStyle:'italic',color:'#aeaeae',margin:3}}>Possible Entries</div>
		                      {entries.map((k,kindex)=>{
		                      	if (kindex == this.state.parseIndex*2 || kindex == this.state.parseIndex*2+1) {
		                      	return <div style={{marginTop:15,marginBottom:10}}>
		                      			<div style={{display:'flex',alignItems:'center',flexDirection:'row',fontWeight:'500'}}>
		                      				<a style={{whiteSpace:'nowrap'}} href={"https://www.haashuka.com/"+k['url']} target="_blank">{k['tlingit']}</a>
				                        	{k['tags'].map((t,tindex)=>
					                        		(tindex == 0 ?
						                          <span style={{display:'flex',marginLeft:'12px'}}>
						                            <TagColors homeMode={0} tags={t} width={window.innerWidth} />
						                          </span>
						                          :
						                          null
				                        			)
					                        	)}
		                      			</div>
		                      			<div style={{fontStyle:'italic',marginTop:'5px'}}>{k['english']}</div>
		                      	</div>
		                      	}})}

		                      {this.state.parseIndex+1 < maxLength+1 && maxLength > 1 ?
			                      <div style={{display:'flex',justifyContent:'center',marginTop:'12px'}}>
										        <Icon disabled={this.state.parseIndex === 0} circular onClick={() => this.setState({parseIndex: this.state.parseIndex-1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron left' />
										        <Label basic circular style={{width:30,color:'#aeaeae',fontWeight:500,height:30,fontSize:14,}}>
										          {this.state.parseIndex + 1}
										        </Label>
										        <Icon disabled={this.state.parseIndex+1 == maxLength} circular onClick={() => this.setState({parseIndex: this.state.parseIndex+1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron right' />
										        </div>  
										        :
										        null
									        }      
	  
		                    </div>
		                  } trigger={
		                    <span style={{cursor:'pointer', marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'6px',fontSize:'20px', wordWrap:'break-word',borderBottom:'2px dotted #b5b5b5'}}>
		                      {this.processStyledText(t[0])}
		                    </span>
		                  } />

	                	}

	                })}

									{d[3].length > 0 ?
										<Icon circular onClick={()=>this.repeatAudio(d[3])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
										:
										null
									}
									</div>


									<div style={{marginBottom:'18px',marginLeft:'22px',fontSize:'16px',color:'#000000DE'}}>
									<span>
									{this.processStyledText(d[2])}
									</span>
									</div>



								</div>
								)}

                <span style={{display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',fontSize:'20px',lineHeight:'30px'}}>
                

                </span>

						</div>
						:
						null
					}


					{this.state.verb_paradigms.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Verb Paradigms</span>
							</div>

							{this.state.verb_paradigms.map((d,ind) =>
								(Object.keys(d[1]).map((e,eindex)=>
									<div style={{marginTop:'18px'}}>
										<div style={{display:'flex',marginTop:'15px',marginLeft:'22px',marginBottom:'5px',justifyContent:'space-between'}}>
											<div style={{fontSize:'20px',lineHeight:'34px'}}>
												{d[1][e][0].map((j,jindex)=><span style={{color:(j=='[does not occur]' || j.includes("ee '") ? '#c2c2c2':null)}}>{this.processStyledText(j)}{jindex !== d[1][e][0].length-1 ? ', ' : ''}</span>)}
												{d[1][e][2].length > 0 ?
													<Icon circular onClick={()=>this.repeatAudio(d[1][e][2])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
													:
													null
												}
											</div>
											{eindex == 0 ? this.returnNonLinguisticTerm(d[0]) : null}
										</div>
										<div style={{marginBottom:'18px',marginLeft:'22px',fontSize:'16px',color:'#777777'}}>
											{d[1][e][1].map((j,jindex)=><span>{this.processStyledText(j)}{jindex !== d[1][e][1].length-1 ? ', ' : ''}</span>)}
										</div>
									</div>									
								))
							)}
{/*

							{Object.keys(this.state.verb_paradigms).map((d,ind) => 
								(this.state.verb_paradigms[d][0][0] === '[does not occur]' ?
									<div style={{margin:'25px 0px'}}>
										<div style={{display:'flex',justifyContent:'center'}}>
											<div style={{fontSize:'16px',color:'#acacac',fontStyle:'italic'}}>
											{d in nonLinguistic ? 
												this.processStyledText(nonLinguistic[d] + ' does not occur')
												:
												this.processStyledText(d.replaceAll('_',' ')+' does not occur')
											}
											</div>

										</div>

									</div>
									:
									null
								)
								)}
*/}

						</div>
						:
						null
					}

					{this.state.siblings.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Siblings</span>
							</div>
							<List style={{marginTop:0}} divided>
							{this.state.siblings.map((key, index) =>
								{
								let word = key
						    return <List.Item style={{padding:7,paddingTop:11,paddingBottom:11}} key={word.url}>
						    <Link to={{pathname: '/' + word.url}} onClick={()=>{this.setState({key:word.url, from: this.props.location.pathname})}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'2px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
						    </Link>
						      <List.Content  style={{paddingRight:'16px'}}>
						        <List.Header style={{fontSize:'16px',paddingBottom:'2px',fontFamily:customFontFam,lineHeight:'25px',paddingLeft:'13px'}}>
				          		<div style={{display:'flex',alignItems:'center'}}> 
                        <Link style={{color:'#306190'}} to={{pathname: '/' + word.url, state: { word: word.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  				              <span style={{'paddingRight':'3px',fontWeight:'500'}}> {/* ,borderBottom:'1px solid #858585',paddingBottom:'1px', */}
                          {this.processStyledTextLink(word.lexicon)}
                        </span>
                        </Link>

                        {'soundfile' in word ?
                          <Icon circular onClick={()=>this.repeatAudio(word.soundfile)} style={{marginLeft:'10px',fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
                          :
                          null
                        }

                        {'dialect' in word ?
                          (word.dialect.map((d,dindex)=>
                            <span style={{'marginLeft':(dindex == 0 ? '12px':'0px'),marginRight:'6px'}}>  
                              <span style={{display:'flex',marginRight:'5px'}}>
                                <TagColors homeMode={0} tags={d} width={window.innerWidth} />
                              </span>
                            </span>
                          ))
                          :
                          null
                        }
                        <span style={{'marginLeft':('dialect' in word ? '0px':'12px'),marginRight:'6px'}}>  
                          <span style={{display:'flex',marginRight:'5px'}}>
                            <TagColors homeMode={0} tags={word.pos} width={window.innerWidth} />
                          </span>
                        </span>

				              </div>
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(word.gloss.join('; '))}</List.Description>
						      </List.Content>
						    </List.Item>
              	})}
							</List>

						</div>
						:
						null
					}

					{this.state.homonyms.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Homonyms</span>
							</div>
							<List style={{marginTop:0}} divided>
							{this.state.homonyms.map((key, index) =>
								{
								let word = key
						    return <List.Item style={{padding:7,paddingTop:11,paddingBottom:11}} key={word.url}>
						    <Link to={{pathname: '/' + word.url}} onClick={()=>{this.setState({key:word.url, from: this.props.location.pathname})}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'2px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
						    </Link>
						      <List.Content  style={{paddingRight:'16px'}}>
						        <List.Header style={{fontSize:'16px',paddingBottom:'2px',fontFamily:customFontFam,lineHeight:'25px',paddingLeft:'13px'}}>
				          		<div style={{display:'flex',alignItems:'center'}}> 
                        <Link style={{color:'#306190'}} to={{pathname: '/' + word.url, state: { word: word.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
  				              <span style={{'paddingRight':'3px',fontWeight:'500'}}> {/* ,borderBottom:'1px solid #858585',paddingBottom:'1px', */}
                          {this.processStyledTextLink(word.lexicon)}
                        </span>
                        </Link>

                        {'soundfile' in word ?
                          <Icon circular onClick={()=>this.repeatAudio(word.soundfile)} style={{marginLeft:'10px',fontSize:'13px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
                          :
                          null
                        }

                        {'dialect' in word ?
                          (word.dialect.map((d,dindex)=>
                            <span style={{'marginLeft':(dindex == 0 ? '12px':'0px'),marginRight:'6px'}}>  
                              <span style={{display:'flex',marginRight:'5px'}}>
                                <TagColors homeMode={0} tags={d} width={window.innerWidth} />
                              </span>
                            </span>
                          ))
                          :
                          null
                        }
                        <span style={{'marginLeft':('dialect' in word ? '0px':'12px'),marginRight:'6px'}}>  
                          <span style={{display:'flex',marginRight:'5px'}}>
                            <TagColors homeMode={0} tags={word.pos} width={window.innerWidth} />
                          </span>
                        </span>

				              </div>
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(word.gloss.join('; '))}</List.Description>
						      </List.Content>
						    </List.Item>
              	})}
							</List>

						</div>
						:
						null
					}

					{this.state.LeerEdwards_Theme.length !== 0 || this.state.NaishStory_Theme.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Theme</span>
							</div>
							{this.state.LeerEdwards_Theme.length !== 0 ?
								<div style={{fontSize:'16px',margin:'20px 20px',marginRight:'0px'}}>
									<div style={{marginBottom:'15px',color:'#777777',fontStyle:'italic'}}><span style={{}}>{'Leer-Edwards Theme'}</span></div>
									<div style={{marginBottom:'10px',fontSize:'20px'}}>
									{this.state.LeerEdwards_Theme[0].map((j)=>{return <span>{this.processStyledText(j)}</span>})}
									</div>
									{this.state.LeerEdwards_Theme[1].map((j)=>{
										return 	<div style={{margin:'3px 0px'}}>
															<span style={{color:'#777777',fontSize:'20px','fontWeight':'300'}}>{'○'}</span>
															<span style={{marginLeft:'20px'}}>
																{this.processStyledText(j)}
															</span>
														</div>
									})}
								</div>
								:
								null
							}
							{this.state.NaishStory_Theme.length !== 0 ?
								<div style={{fontSize:'16px',margin:'20px 20px',marginRight:'0px'}}>
									<div style={{marginBottom:'15px',color:'#777777',fontStyle:'italic'}}><span style={{}}>{'Naish-Story Theme'}</span></div>
									<div style={{marginBottom:'10px',fontSize:'20px'}}>
										<span>{this.processStyledText(this.state.NaishStory_Theme)}</span>
									</div>
								</div>
								:
								null
							}
						</div>
						:
						null
					}

					{this.state.Verb_Root.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Verb Root</span>
							</div>
							<div style={{marginTop:'18px',marginBottom:'18px'}}>
								<div style={{marginBottom:'10px'}}>
								<Link to={{pathname: '/' + this.state.Verb_Root, state: { word: this.state.Verb_Root, from: this.props.location.pathname }}}><span className='extrainformation'  style={{borderBottom:'1px solid #306190',paddingBottom:'1px',color:'#306190'}}>{this.state.Verb_Root}</span></Link>
								</div>
							</div>
						</div>
						:
						null
					}
					



					{this.state.semantic_domain.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Category List</span>
							</div>
							<div style={{marginTop:'18px',marginBottom:'18px'}}>
							{this.state.semantic_domain.map((d) => 
								<div style={{marginBottom:'10px'}}>
								<Link to={{pathname: '/', state: { currentCategory: d.split(' ')[0], homeMode:3 }}}><span className='extrainformation'  style={{borderBottom:'1px solid #306190',paddingBottom:'1px',color:'#306190'}}>{d.split(' ').slice(1).join(' ')}</span></Link>
								</div>
							)}
							</div>
						</div>
						:
						null
					}




					{this.state.reversal.length !== 0 && false?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>English Lookup</span>
							</div>
							{this.state.reversal.map((d) => 
								<div style={{marginTop:'18px',marginBottom:'18px'}} className='extrainformation'>{this.processStyledText(d)}</div>
							)}
						</div>
						:
						null
					}



					{this.state.verb_paradigms_roots.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>More Verb Paradigms</span>
							</div>
							<div style={{margin:22,fontSize:'16px',fontStyle:'italic',color:'#777777'}}>These paradigms are from the <a href="http://ankn.uaf.edu/~tlingitverbs/" target="_blank">Online Tlingit Verb Dictionary</a> on the Alaska Native Knowledge Network.</div>
							{this.state.verb_paradigms_roots.map((d,ind) =>
								(Object.keys(d[1]).map((e,eindex)=>
									<div style={{marginTop:'18px'}}>
										<div style={{display:'flex',marginTop:'15px',marginLeft:'22px',marginBottom:'5px',justifyContent:'space-between'}}>
											<div style={{fontSize:'20px',lineHeight:'34px'}}>
												{d[1][e][0].map((j,jindex)=><span style={{color:(j=='[does not occur]' || j.includes("ee '") ? '#c2c2c2':null)}}>{this.processStyledText(j)}{jindex !== d[1][e][0].length-1 ? ', ' : ''}</span>)}
												{d[1][e][2].length > 0 ?
													<Icon circular onClick={()=>this.repeatAudio(d[1][e][2])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
													:
													null
												}
											</div>
											{eindex == 0 ? this.returnNonLinguisticTerm(d[0]) : null}
										</div>
										<div style={{marginBottom:'18px',marginLeft:'22px',fontSize:'16px',color:'#777777'}}>
											{d[1][e][1].map((j,jindex)=><span>{this.processStyledText(j)}{jindex !== d[1][e][1].length-1 ? ', ' : ''}</span>)}
										</div>
									</div>									
								))
							)}
						</div>
						:
						null
					}

					{this.state.noun_paradigms.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Noun Paradigms</span>
							</div>

							{this.state.noun_paradigms.map((d,ind) =>
									<div style={{marginTop:'18px'}}>
										<div style={{display:'flex',marginTop:'15px',marginLeft:'22px',marginBottom:'5px',justifyContent:'space-between'}}>
											<div style={{fontSize:'20px',lineHeight:'34px'}}>
												{d[1][0].map((j,jindex)=><span style={{color:(j=='[does not occur]' ? '#c2c2c2':null)}}>{this.processStyledText(j)}{jindex !== d[1][0].length-1 ? ', ' : ''}</span>)}
												{d[1][2].length > 0 ?
													<Icon circular onClick={()=>this.repeatAudio(d[1][2])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
													:
													null
												}
											</div>
											{this.returnNonLinguisticTerm(d[0])}
										</div>
										<div style={{marginBottom:'18px',marginLeft:'22px',fontSize:'16px',color:'#777777'}}>
											{d[1][1].map((j,jindex)=><span>{this.processStyledText(j)}{jindex !== d[1][1].length-1 ? ', ' : ''}</span>)}
										</div>
									</div>									
							)}
						</div>
						:
						null
					}

					{this.state.adverb_paradigms.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Adverb Paradigms</span>
							</div>

							{this.state.adverb_paradigms.map((d,ind) =>
									<div style={{marginTop:'18px',marginBottom:'18px'}}>
										<div style={{display:'flex',marginTop:'15px',marginLeft:'22px',marginBottom:'5px',justifyContent:'space-between'}}>
											<div style={{fontSize:'20px',lineHeight:'34px'}}>
												{d[1].map((j,jindex)=><span style={{color:(j=='[does not occur]' ? '#c2c2c2':null)}}>{this.processStyledText(j)}{jindex !== d[1].length-1 ? ', ' : ''}</span>)}
												{d[2].length > 0 ?
													<Icon circular onClick={()=>this.repeatAudio(d[2])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
													:
													null
												}
											</div>
											{this.returnNonLinguisticTerm(d[0])}
										</div>
										{/*<div style={{marginBottom:'18px',marginLeft:'22px',fontSize:'16px',color:'#777777'}}>
											{d[1][1].map((j,jindex)=><span>{console.log(j)}{this.processStyledText(j)}{jindex !== d[1][1].length-1 ? ', ' : ''}</span>)}
										</div>*/}
									</div>									
							)}
						</div>
						:
						null
					}


					{this.state.notes1.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Notes 1</span>
							</div>
							{this.state.notes1.map((d) => 
								<div style={{marginTop:'18px',marginBottom:'18px'}} className='extrainformation'>{this.processStyledText(d)}</div>
							)}
						</div>
						:
						null
					}

					{this.state.notes2.length !== 0 && false ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'></span>
							<span className='span2'>Notes 2</span>
							</div>
							{this.state.notes2.map((d) => 
								<div style={{marginTop:'18px',marginBottom:'18px'}} className='extrainformation'>{this.processStyledText(d)}</div>
							)}
						</div>
						:
						null
					}

				</div>

					<div style={{paddingTop:'10px',paddingBottom:'30px',paddingLeft:'8px'}}>
			    <Link to={{pathname: '/report'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'14px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
          </Link>
					</div>


				</Grid.Column>
				</Grid.Row>
				</Grid>
				</Transition>
			</div>
		);
	}
}
export default Entry;